//wkb banner slider
var $wkb_banner = $(document).find('.wkb-banner');
if( $wkb_banner.length > 0 ) {
  $.each($wkb_banner, function( index, banner ) {
    var $banner = $(banner);
    var $slider = $('.owl-carousel', $banner);
    var $slides = $('.item', $banner);

    if( $slider.length > 0  && $slides.length > 1 ) {
      var owl = $slider.owlCarousel({
        items: 1,
        dots: true,
        dotsContainer: $wkb_banner.find('.wkb-banner-nav-dots'),
        smartSpeed: 500,
        nav: false,
        loop: false,
        autoplay: $banner.data('autoslide'),
        autoplayTimeout: $banner.data('interval'),
      });

      owl.on('changed.owl.carousel', function(property) {
        //stop all videos
        stopAllVideos($banner, $videos, $yt_videos);
      });
    }

    var video_type = null;
    if($('.video', $slides[0]).length > 0) {
      video_type = 'file';
    } else if ($('.yt-video', $slides[0]).length > 0) {
      video_type = 'youtube';
    }

    //video controls
    var autoplay = $banner.data('autoplay');
    var $videos = $banner.find('.video');
    var $yt_videos = $banner.find('.yt-video');
    
    if( $videos.length > 0 || $yt_videos.length > 0) {
      //autoplay first slide video
      if(video_type == 'file') {
        var video = $('.video', $slides[0]).get(0);
        if( typeof video != 'undefined' && autoplay == 1 ) {
          playVideo($banner, video);
        }
        
      } else if(video_type == 'youtube') {

        var video = $('.yt-video', $slides[0]).get(0);
        if( typeof video != 'undefined' && autoplay == 1 ) {
          $banner.addClass('playing');
        }
      }
      
      $('.play-btn', $banner).on('click', function() {
        if($(this).next('.video').length > 0) {
          var video = $(this).next('.video').get(0);
          playVideo($banner, video);
        }

        if($(this).next('.yt-video').length > 0) {
          var video = $(this).next('.yt-video').get(0);
          playYoutubeVideo($banner, video);
        } 
      });
    }
  });
}

function playVideo($banner, video) {
  video.play();
  $banner.addClass('playing');

  video.onended = function(e) {
    $banner.removeClass('playing');
  };
}

function stopAllVideos($banner, $videos, $yt_videos) {
  $videos.each(function() {
    $(this).get(0).pause();
    $(this).get(0).currentTime = 0;
  });

  $yt_videos.each(function() {
    var src = $(this).attr('src');
    src = src.replace('&autoplay=1', '');
    $(this).attr('src', src);
  });

  $banner.removeClass('playing');
}

function playYoutubeVideo($banner, video) {
  $banner.addClass('playing');
  var src = $(video).attr('src');
  src += '&autoplay=1';
  src = src.replace('&mute=1', '');
  $(video).attr('src', src);
}

$(document).on('ready', function() {
  $('header').not('.has-banner').parent().addClass('content-down');
});