export default {
  init() {
    // JavaScript to be fired on all pages

    // header search form trigger
    let searchForm = $(document).find('header .search-form');
    $('.trigger', searchForm).on('click', function() {
      $(searchForm).toggleClass('active');
    });

    // Slow scroll with anchors
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[data-toggle="tab"]').click(function (event) {
      if(!$(this).closest('.wc-tabs' ).length) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length > 0) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        }
      }
    });

    //Back to top button
    let toTopButton = document.getElementById("btn-back-to-top");
    
    // When the user scrolls down 20px from the top of the document, show the button
    if(toTopButton) {

      window.onscroll = function () {
        scrollFunction();
      };
      // When the user clicks on the button, scroll to the top of the document
      toTopButton.addEventListener("click", backToTop);
    }

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        toTopButton.style.display = "flex";
      } else {
        toTopButton.style.display = "none";
      }
    }
    
    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    // Animations with animate.css
    function callbackFunc(entries, observer) {
      entries.forEach(entry => {
        let $e = $(entry.target);
        if(entry.isIntersecting) {
          let $animation_e = $e;
          let animation = $animation_e.data('animation');
          
          setTimeout(function() {
            $e.addClass('animate_start ' + animation);
          }, 400);
        }
      });
    }
    
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };
    
    let observer = new IntersectionObserver(callbackFunc, options);
    let $animation_elements = $('.animate__animated');

    $animation_elements.each(function( e ) {
      observer.observe($(this)[0]);
    });

    $(".icon.icon-hamburger").on('click', function() {
      // $(this).removeClass('icon icon-hamburger');
      // $(this).addClass('icon icon-close');
      $('body').toggleClass('open-menu');
      $(this).toggleClass("icon icon-hamburger icon icon-close");
    });

    $('.widget_polylang select').select2({
      minimumResultsForSearch: -1,
      width: '100%',
      dropdownParent: $('.widget_polylang')
    });

    $('.widget_polylang select').on('select2:select', function (e) {
      location.href = e.params.data.id;
    });

    
    // Create a new object for custom validation of a custom field.
    var mySubmitController = Marionette.Object.extend( {
      
      initialize: function() {
        this.listenTo( Backbone.Radio.channel( 'forms' ), 'submit:response', this.actionSubmit );
      },

      actionSubmit: function( response ) {
        $(window).scrollTop(0);
      },

    });

    // On Document Ready...
    jQuery( document ).ready( function( $ ) {

        // Instantiate our custom field's controller, defined above.
        new mySubmitController();
    });

    // // Navbar show on scroll up
    // let prevScrollPos = window.scrollY;

    // window.addEventListener('scroll', function() {
    //   // current scroll position
    //   const currentScrollPos = window.scrollY;

    //   if (prevScrollPos > currentScrollPos) {
    //     // user has scrolled up
    //     document.querySelector('.has-banner').classList.remove('hide');
    //   } else {
    //     // user has scrolled down
    //     document.querySelector('.has-banner').classList.add('hide');
    //   }

    //   // update previous scroll position
    //   prevScrollPos = currentScrollPos;
    // });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
