import LeaderLine from 'leader-line-new';

var $wkb_map = $('.wkb-map');
if ($wkb_map.length > 0) {
  $.each($wkb_map, function(index, map) {
    var $map = $(map);
    var $centerPosition = $map.find('.center-div'); //.position();
    var line;
    var $pointer = $map.find('.point-container');

    // Puls effect
    setInterval(() => {
      $pointer.toggleClass('small');
    }, 3000);

    // Mouse over
    $map.find('.location-trigger span')
      .on('mouseenter', function() {
        var $location = $($map.find('.map .location')[$(this).parent().index()]); 
        $location.addClass('active');
        $pointer.css({'left': $(this).parent().data('left') + '%', 'top': $(this).parent().data('top') + '%'});

        line = new LeaderLine($centerPosition[0], $location[0], {
          color: 'white',
          path: 'straight',
          endPlug: 'disc',
          size: 4
        });

      })
      .on('mouseleave', function() {
        $($map.find('.map .location')[$(this).parent().index()]).removeClass('active');
        $pointer.removeAttr('style');

        line.remove();
      });

    $
  });
}