var $wkb_posts = $(document).find('.wkb-posts, .wkb-cards');
if( $wkb_posts.length > 0 ) {
  $.each($wkb_posts, function( index, posts ) {
    var $posts = $(posts);
    var $slides = $('.owl-carousel', $posts);
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: 1,
        dots: true,
        dotsContainer: '#' + $posts.find('.owl-dots').prop('id'),
        nav: false,
        loop: false,
        margin: 32,
        stagePadding: 50,
        responsive : {
          // breakpoint from 480 up
          480 : {
            items: 1,
          },
          // breakpoint from 768 up
          768 : {
            items: 3,
          }
        }
      });
      $('.owl-dot', $posts).click(function () {
        owl.trigger('to.owl.carousel', [$(this).index(), 300]);
      });
    }

  });
}
