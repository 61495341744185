//wkb banner slider
var $wkb_testimonials = $(document).find('.wkb-testimonials');
if( $wkb_testimonials.length > 0 ) {
  var reinitTimer;

  InitSlider();

  $(window).resize(function () {
    clearTimeout(reinitTimer);
    reinitTimer = setTimeout(InitSlider, 100);
  });

  function InitSlider() {
    $.each($wkb_testimonials, function( index, testimonial ) {
      var $testimonial = $(testimonial);
      var $slides = $('.wkb-testimonials__inner', $testimonial);
      var columns = $testimonial.data('columns');
      if( $slides.length > 0 ) {
        if ( window.innerWidth < 769) {
          $slides.addClass('owl-carousel');
          $slides.owlCarousel({
            items: 1,
            dots: true,
            dotsContainer: '#' + $testimonial.find('.owl-dots').prop('id'),
            nav: false,
            loop: false,
            margin: 32,
            stagePadding: 55
          });
          $('.owl-dot', $testimonial).click(function () {
            $slides.trigger('to.owl.carousel', [$(this).index(), 300]);
          });
        } else {
          if (typeof $slides.data('owl.carousel') != 'undefined') {
            $slides.data('owl.carousel').destroy();
          }

          $slides.removeClass('owl-carousel');
        }
      }
    });
  }
}
